import { useSearchParams, useNavigate } from "react-router-dom";
import sendFormDataJson from "../utils/sendFormDataJson";
import PasswordSetter from "./PasswordSetter";
import { useState, useEffect } from "react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

export default function Registration() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [requestSuccessful, setRequestSuccessful] = useState(false);

  async function handleSubmit(evt) {
    evt.preventDefault();
    const resp = await sendFormDataJson(evt);
    if (resp.ok) {
      setRequestSuccessful(true);

      if (searchParams.has("token")) {
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      }
    }
  }

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            alt="Your Company"
            src="https://tailwindui.com/plus/img/logos/mark.svg?color=indigo&shade=600"
            className="mx-auto h-10 w-auto"
          />
          <h2 className="mt-10 text-center text-2xl/9 font-bold tracking-tight text-gray-900">
            Passwort zurücksetzen
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form
            action="/api/password-reset"
            method="POST"
            className="space-y-6"
            onSubmit={handleSubmit}
          >
            {searchParams.has("token") ? (
              <>
                <input
                  type="hidden"
                  name="token"
                  value={searchParams.get("token")}
                />
                <input
                  type="hidden"
                  name="username"
                  value={searchParams.get("email")}
                />
                <PasswordSetter />
              </>
            ) : (
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm/6 font-medium text-gray-900"
                >
                  Email Adresse
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    required
                    autoComplete="email"
                    className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                  />
                </div>
              </div>
            )}

            {!requestSuccessful ? (
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {searchParams.has("token")
                  ? "Passwort speichern"
                  : "Passwort zurücksetzen"}
              </button>
            ) : (
              <div className="rounded-md bg-green-50 p-4">
                <div className="flex">
                  <div className="shrink-0">
                    <CheckCircleIcon
                      aria-hidden="true"
                      className="size-5 text-green-400"
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-green-800">
                      {!searchParams.has("token")
                        ? `Es wurde an die angegebene E-Mail-Adresse eine Nachricht
                      mit weiteren Anweisungen gesendet.`
                        : `Das neue Passwort wurde erfolgreich gespeichert.`}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </form>
          {!searchParams.has("token") && (
            <a
              href="/login"
              className="block mt-5 text-center text-sm/6 font-medium text-indigo-600 hover:text-indigo-500"
            >
              Zurück zum Login
            </a>
          )}
        </div>
      </div>
    </>
  );
}

import { useState, useEffect, useContext } from "react";
import NiceModal from "@ebay/nice-modal-react";
import { Field, Label, Switch } from "@headlessui/react";

import { UserContext } from "../App";
import sendFormDataJson from "../utils/sendFormDataJson";
import VariantsEditor from "./VariantsEditor";

export function Settings() {
  const user = useContext(UserContext);
  const [settings, setSettings] = useState({});
  const [availableVariants, setAvailableVariants] = useState([]);
  const [customSMTPServer, setCustomSMTPServer] = useState(false);
  async function handleSubmit(evt) {
    evt.preventDefault();
    return await sendFormDataJson(evt);
  }
  async function handleInputChange(evt) {
    setSettings({ ...settings, [evt.target.id]: evt.target.value });
  }
  async function handleVariantDelete(variant_id) {
    const response = await fetch("/api/admin/variants/" + variant_id, {
      method: "DELETE",
    });
    if (response.ok) {
      setAvailableVariants(
        availableVariants.filter(
          (variant) => variant.ressource_id !== variant_id
        )
      );
    } else {
      alert("Es ist ein Fehler beim Löschen der Variante aufgetreten.");
      console.error(response);
    }
  }
  function handleEditVariantBtn(variant) {
    NiceModal.show(VariantsEditor, {
      variant: variant,
      addVariantToState: (newVariant) => {
        let newVariantList;
        if (variant) {
          console.debug(variant);

          newVariantList = availableVariants.map((v) =>
            v.ressource_id === variant.ressource_id ? newVariant : v
          );
        } else {
          newVariantList = [...availableVariants, newVariant];
        }
        console.log(newVariantList);
        setAvailableVariants(newVariantList);
      },
    });
  }

  useEffect(() => {
    (async () => {
      const response = await fetch("/api/admin/settings/email", {
        credentials: "same-origin",
      });
      if (response.ok) {
        const data = await response.json();
        setSettings(data);
      }
    })();
  }, []);
  useEffect(() => {
    (async () => {
      const response = await fetch("/api/admin/variants", {
        credentials: "same-origin",
      });
      if (response.ok) {
        const data = await response.json();
        setAvailableVariants(data);
      }
    })();
  }, []);

  return (
    <>
      <form
        action="/api/admin/settings/email"
        method="post"
        onSubmit={handleSubmit}
      >
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              E-Mail
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Diese Informationen werden benötigt, um nach einer Buchung
              Bestätigungsmails versenden zu können.
            </p>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <label
                  htmlFor="sender"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Absender / SMTP Benutzername
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      id="email_from"
                      name="email_from"
                      value={settings.email_from || ""}
                      onChange={handleInputChange}
                      type="email"
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>

              <div className="sm:col-span-4">
                <label
                  htmlFor="bccrecipient"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  BCC Empfänger
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      id="email_bcc"
                      name="email_bcc"
                      value={settings.email_bcc || ""}
                      onChange={handleInputChange}
                      type="email"
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="subject"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Betreff
                </label>
                <div className="mt-2">
                  <div className="block w-full rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
                    <input
                      id="email_subject"
                      name="email_subject"
                      value={settings.email_subject || ""}
                      onChange={handleInputChange}
                      type="text"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="email_body"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  E-Mail Text
                </label>
                <div className="mt-2">
                  <textarea
                    id="email_body"
                    name="email_body"
                    value={settings.email_body || ""}
                    onChange={handleInputChange}
                    rows={6}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                <p className="mt-3 text-sm leading-6 text-gray-600">
                  Folgende Variablen können benutzt werden:
                  &#123;&#123;name&#125;&#125;,
                  &#123;&#123;booked_time&#125;&#125;,
                  &#123;&#123;booked_variant&#125;&#125;,
                  &#123;&#123;attendees_count&#125;&#125;,
                  &#123;&#123;cancel_url&#125;&#125; und
                  &#123;&#123;booked_time_unformatted&#125;&#125; (kann mit
                  <a href="https://docs.python.org/3/library/datetime.html#strftime-and-strptime-format-codes">
                    <code>strftime</code> formatiert werden
                  </a>
                  )
                </p>
              </div>
              <div className="sm:col-span-4">
                <Field className="flex items-center">
                  <Switch
                    id="email_custom_smtpserver"
                    checked={settings.email_custom_smtpserver}
                    onChange={() => {
                      setSettings({
                        ...settings,
                        email_custom_smtpserver:
                          !settings.email_custom_smtpserver,
                      });
                    }}
                    name="email_custom_smtpserver"
                    className="group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600"
                  >
                    <span
                      aria-hidden="true"
                      className="pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                    />
                  </Switch>
                  <Label as="span" className="ml-3 text-sm">
                    <span className="font-medium text-gray-900">
                      Eigenen SMTP-Server benutzen
                    </span>{" "}
                    <span className="text-gray-500">
                      (sonst werden die Mails von DoNotReply@bookingal.de
                      versendet)
                    </span>
                  </Label>
                </Field>
              </div>
              <div className="sm:col-span-4">
                <label
                  htmlFor="smtpserver"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  SMTP Server
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      id="email_smtpserver"
                      name="email_smtpserver"
                      type="text"
                      value={settings.email_smtpserver || ""}
                      onChange={(evt) => handleInputChange(evt)}
                      readOnly={!settings.email_custom_smtpserver}
                      placeholder="smtp.strato.com"
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 isabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200"
                    />
                  </div>
                </div>
              </div>

              <div className="sm:col-span-4">
                <label
                  htmlFor="smtppassword"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  SMTP Passwort
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      id="email_password"
                      name="email_password"
                      value={settings.email_password || ""}
                      onChange={handleInputChange}
                      readOnly={!settings.email_custom_smtpserver}
                      type="text"
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6">
              {/*<button
            type="button"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </button>*/}
              <button
                type="submit"
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Speichern
              </button>
            </div>
          </div>
        </div>
      </form>
      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-12">
          <h2
            className="text-base font-semibold leading-7 text-gray-900"
            id="variants"
          >
            Varianten
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Hier können die verfügbaren Terminvarianten bearbeitet werden.
          </p>

          <ul className="divide-y divide-gray-100">
            {availableVariants.map((variant) => (
              <li
                key={variant.ressource_id}
                className="flex items-center justify-between gap-x-6 py-5"
              >
                <div className="min-w-0">
                  <div className="flex items-start gap-x-3">
                    <p className="text-sm font-semibold leading-6 text-gray-900">
                      {variant.name}
                    </p>
                  </div>
                  <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                    <p className="whitespace-nowrap">
                      {variant.short_description}
                      {variant.leadtime > 0 &&
                        ` (Vorlaufzeit: ${variant.leadtime / 60} Min.)`}
                    </p>
                  </div>
                </div>
                <div className="flex flex-none items-center gap-x-4">
                  <button
                    onClick={() => handleEditVariantBtn(variant)}
                    className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                  >
                    Bearbeiten
                  </button>
                  {availableVariants.length > 1 && (
                    <button
                      onClick={() => handleVariantDelete(variant.ressource_id)}
                      className="hidden rounded-md bg-red-800 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover: hover:bg-stone-700 sm:block"
                    >
                      Löschen
                    </button>
                  )}
                </div>
              </li>
            ))}
          </ul>
          <div className="mt-6 flex items-center justify-end gap-x-6">
            {/*<button
            type="button"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </button>*/}
            <button
              onClick={() => handleEditVariantBtn(null)}
              type="button"
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              + Variante anlegen
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

import "./App.css";
import { useState, useEffect, useContext, createContext } from "react";
import NiceModal from "@ebay/nice-modal-react";
import {
  CheckIcon,
  LockClosedIcon,
  TrashIcon,
  PencilIcon,
  ChatBubbleBottomCenterIcon,
  CalendarIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/24/outline";
import { TrashIcon as TrashIconMicro } from "@heroicons/react/24/outline";
import { CalendarDaysIcon } from "@heroicons/react/20/solid";
import {
  Routes,
  Route,
  NavLink,
  Outlet,
  Navigate,
  useOutletContext,
} from "react-router-dom";

import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";

import { UserList, getTeam } from "./components/userlist";
import { Settings } from "./components/Settings";
import { Help } from "./components/Help";
import { RessourceFilter } from "./components/RessourceFilter";
import BookingForm from "./components/BookingForm";
import AddTimeslotDialog from "./components/AddTimeslotDialog";
import PasswordEditor from "./components/PasswordEditor";
import Registration from "./components/Registration";
import PasswordReset from "./components/PasswordReset";

import sendFormData from "./utils/sendFormData";
import sendFormDataJson from "./utils/sendFormDataJson";
import EmptyTimeslots from "./components/EmptyTimeslots";

const navigation = [
  { name: "Dashboard", href: "/admin", admin: false },
  { name: "Einstellungen", href: "/admin/settings", admin: true },
  { name: "Team", href: "/admin/team", admin: true },
  { name: "Hilfe", href: "/admin/help", admin: true },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const UserContext = createContext(null);

const postFormdata = async function (form, url) {
  const response = await fetch(form.action, {
    method: "post",
    body: new URLSearchParams(new FormData(form)),
  });
  if (!response.ok) {
    alert("Es ist ein Fehler beim Speichern ihrer Eingabe aufgetreten");
  }
  return response;
};

async function deleteTimeslot(timeslot_id) {
  const response = await fetch("/api/admin/timeslot/" + timeslot_id, {
    method: "DELETE",
  });
  if (!response.ok) {
    alert("Es ist ein Fehler beim Löschen des Termins aufgetreten.");
    console.error(response);
  }
  return response;
}

function RessourceComment({ slot_id, comment }) {
  let [showEditor, setShowEditor] = useState(false);
  let [newComment, setComment] = useState(comment);
  let [commentChanged, setCommentChanged] = useState(false);
  const user = useContext(UserContext);
  function handleShowEditorButton() {
    setShowEditor(!showEditor);
  }
  function handleCommentChange(evt) {
    setComment(evt.target.value);
    setCommentChanged(true);
  }
  async function handleCommentSubmit(evt) {
    evt.preventDefault();
    await sendFormData(evt, evt.target);
    setCommentChanged(false);
    setShowEditor(false);
    comment = newComment;
  }

  return (
    <>
      {user.admin && (
        <div>
          <div className="flex">
            <button
              className="p-1 rounded-full bg-orange-500 p-1 w-6 h-6 text-white leading-3"
              onClick={handleShowEditorButton}
              title="Kommentar hinzufügen/bearbeiten"
            >
              <ChatBubbleBottomCenterIcon className="h-4 w-4" />
            </button>
            {showEditor && (
              <form
                action={`/api/admin/event/${slot_id}/comment`}
                method="post"
                className="w-full"
                onSubmit={handleCommentSubmit}
              >
                <textarea
                  name="comment"
                  value={newComment || ""}
                  placeholder="Kommentar zur Buchung"
                  className="w-5/6"
                  onChange={handleCommentChange}
                ></textarea>
                {commentChanged && (
                  <button
                    type="submit"
                    className="rounded-full bg-orange-500 p-1 w-6 h-6 text-white leading-3"
                  >
                    <CheckIcon />
                  </button>
                )}
              </form>
            )}
          </div>
        </div>
      )}
      {!showEditor && <p className="italic px-2">{newComment}</p>}
    </>
  );
}
function Booking({ booking }) {
  const user = useContext(UserContext);
  return (
    <li className="text-sm flex group/booking">
      <a href={"mailto:" + booking.booker_email} className="font-medium mr-2">
        {booking.booker_name}
      </a>

      <span className="italic">{booking.comment} </span>
      <span className="font-thin">{`(${booking.attendees_count} P)`}</span>
      {user.admin && (
        <a
          href={`/cancel.html?cancel_token=${booking.cancel_token}`}
          className="hidden group-hover/booking:inline-flex items-center px-3"
        >
          <TrashIcon className="h-3 w-3" />
        </a>
      )}
    </li>
  );
}

function StaffAssignement({ timeslot, availableStaff }) {
  let [assignedStaff, setStaff] = useState(timeslot.assigned_staff);
  let [staffChanged, setStaffChanged] = useState(false);
  const user = useContext(UserContext);
  async function handleDelete(evt, member) {
    evt.preventDefault();
    let resp = await fetch(
      `/api/admin/timeslot/${timeslot.slot_id}/staff/${member}`,
      { method: "DELETE" }
    );
    if (resp.ok) {
      setStaff(assignedStaff.filter((m) => m !== member));
    }
  }
  async function handleStaffAssignement(evt) {
    evt.preventDefault();
    let resp = await postFormdata(evt.target);
    if (resp.ok) {
      setStaff([...assignedStaff, new FormData(evt.target).get("staff_name")]);
      setStaffChanged(false);
    }
    //changed = false;
  }
  return (
    <>
      {assignedStaff.map((member) => (
        <span
          key={member.staff_name}
          className="inline-flex items-center gap-x-0.5 rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10"
        >
          <span>{member}</span>
          {user.admin && (
            <button
              type="button"
              className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-gray-500/20"
              onClick={(evt) => handleDelete(evt, member)}
            >
              <span className="sr-only">Remove</span>
              <svg
                viewBox="0 0 14 14"
                className="h-3.5 w-3.5 stroke-gray-600/50 group-hover:stroke-gray-600/75"
              >
                <path d="M4 4l6 6m0-6l-6 6" />
              </svg>
              <span className="absolute -inset-1"></span>
            </button>
          )}
        </span>
      ))}
      {user.admin && (
        <div>
          <form
            action="/api/admin/assign_staff"
            method="post"
            className="flex"
            onSubmit={handleStaffAssignement}
          >
            <select
              name="staff_name"
              className="bg-stone-200 mt-1 block rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
              onChange={(evt) => setStaffChanged(true)}
              defaultValue=""
            >
              <option value="">---</option>
              {availableStaff &&
                availableStaff
                  .filter(
                    (member) => !assignedStaff.includes(member.staff_name)
                  )
                  .map((member) => (
                    <option value={member.staff_name} key={member.staff_name}>
                      {member.staff_name}
                    </option>
                  ))}
            </select>
            <input
              type="hidden"
              value={timeslot.slot_id}
              name="event_id"
              className="rounded-full bg-orange-500 p-1 w-6 h-6"
            />
            {staffChanged && (
              <button
                type="submit"
                className="rounded-full bg-orange-500 p-1 w-6 h-6 text-white leading-3"
              >
                <CheckIcon />
              </button>
            )}
          </form>
        </div>
      )}
    </>
  );
}

function Ressource({ ressource, staff, deleteRessource, updateTimeslots }) {
  const [active, setActive] = useState(false);
  const user = useContext(UserContext);
  function handleDeleteTimeslot() {
    if (timeslot.bookings[0]) {
      if (
        !window.confirm(
          `Achtung! Für diesen Termin gibt es ${ressource.bookings.length} Buchungen. Soll der Termin trotzdem gelöscht werden?`
        )
      ) {
        return;
      }
    }
    deleteRessource(ressource.slot_id);
  }
  async function addBooking(data) {
    const bookingData = { ...data, starttime: ressource.start };
    let resp = await fetch("/api/admin/booking", {
      method: "post",
      body: JSON.stringify(bookingData),
      headers: {
        "Content-Type": "application/json",
      },
    });
    updateTimeslots();
    return resp;
  }
  function handleAddBooking(evt) {
    NiceModal.show(BookingForm, {
      variants: ressource.bookings[0]
        ? [ressource.bookings[0].ressource_name]
        : ressource.available_ressources,
      returnFormData: addBooking,
    });
  }
  function handleEditRessource(evt) {
    NiceModal.show(AddTimeslotDialog, {
      ressource: ressource,
      addRessourceToState: updateTimeslots,
    });
  }
  let timeslot = ressource;
  timeslot.start = new Date(timeslot.start);
  return (
    <>
      <li
        id={timeslot.start.toISOString()}
        onClick={() => setActive(!active)}
        className="group"
      >
        <div>
          <figure
            className={
              "bg-gray-100 rounded-xl pt-4 my-2 print:border + {'bg-stone-200 pt-1': !timeslot.sum, 'border border-orange-500': selected}"
            }
          >
            <div className="flex px-4 pb-1">
              <div className="pr-4">
                <h4 className="text-xl" title={timeslot.start.toLocaleString()}>
                  {`${timeslot.start.toLocaleString(undefined, {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}`}
                </h4>
              </div>
              <div className="flex-auto">
                <div x-if="timeslot.sum">
                  <ul>
                    {timeslot.bookings.map(
                      (booking) =>
                        booking && (
                          <Booking booking={booking} key={booking.booking_id} />
                        )
                    )}
                  </ul>
                </div>
                {user.admin && (
                  <button onClick={handleAddBooking} className="text-xs">
                    Buchung hinzufügen
                  </button>
                )}
              </div>
              <div>
                {user.admin && (
                  <div className="flex space-x-1">
                    <button
                      type="button"
                      onClick={handleEditRessource}
                      className="hidden group-hover:inline-flex grow-0 items-center gap-x-1.5 rounded-md bg-white ring-1 ring-inset ring-orange-500 px-2.5 py-1 text-sm text-orange-500 shadow-sm hover:bg-orange-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                    >
                      <PencilIcon className="w-3 h-3" />
                      Bearbeiten
                    </button>
                    <button
                      type="button"
                      onClick={handleDeleteTimeslot}
                      className="hidden group-hover:inline-flex items-center gap-x-1.5 rounded-md bg-white ring-1 ring-inset ring-red-500 px-2.5 py-1 text-sm text-red-500 shadow-sm hover:bg-red-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                    >
                      <TrashIcon className="w-3 h-3" />
                      Löschen
                    </button>
                  </div>
                )}
                <div className="flex">
                  <div className={user.admin && "group-hover:hidden"}>
                    {timeslot.bookings[0] && (
                      <span className="italic text-sm">
                        {timeslot.bookings[0].ressource_name}
                      </span>
                    )}
                    {!timeslot.bookings[0] && (
                      <span className="font-thin italic text-sm">
                        {timeslot.available_ressources.join(", ")}
                      </span>
                    )}

                    {timeslot.internal && (
                      <span className="font-thin italic text-sm">
                        {" "}
                        (intern)
                      </span>
                    )}
                  </div>
                  {user.admin && (
                    <Menu
                      as="div"
                      className="sm:hidden relative inline-block text-left"
                    >
                      <div>
                        <MenuButton className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                          <span className="sr-only">Open options</span>
                          <EllipsisVerticalIcon
                            aria-hidden="true"
                            className="size-5"
                          />
                        </MenuButton>
                      </div>

                      <MenuItems
                        transition
                        className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                      >
                        <div className="py-1">
                          <MenuItem>
                            <button
                              className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                              onClick={handleEditRessource}
                            >
                              Bearbeiten
                            </button>
                          </MenuItem>
                          <MenuItem>
                            <a
                              href="#"
                              className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                            >
                              Löschen
                            </a>
                          </MenuItem>
                        </div>
                      </MenuItems>
                    </Menu>
                  )}
                </div>
              </div>
            </div>
            <div className="flex rounded-b-xl px-4 bg-stone-200">
              <div className="flex flex-wrap flex-auto">
                <div className="flex flex-auto items-center">
                  <RessourceComment
                    slot_id={ressource.slot_id}
                    comment={timeslot.comment}
                  />
                </div>
                <StaffAssignement timeslot={timeslot} availableStaff={staff} />
              </div>
            </div>
          </figure>
        </div>
      </li>
    </>
  );
}

function LoginScreen({ setUser }) {
  const [loginfailed, setLoginfailed] = useState(false);
  async function handleSubmit(event) {
    event.preventDefault();
    let resp = await sendFormData(event, event.target);
    if (resp.ok) {
      setLoginfailed(false);
      const response = await fetch("/api/users/me/", {
        credentials: "same-origin",
      });
      if (response.ok) {
        const data = await response.json();
        setUser(data);
      } else {
        setUser(null);
      }
    } else {
      setLoginfailed(true);
    }
  }
  /*@submit.prevent=" resp = (await sendFormData($event,$event.target)); if (resp.ok) {location.reload()} else { failedlogin = true}"*/
  return (
    <>
      <div
        className="relative z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div
              className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6"
              id="login-modal-panel"
            >
              <div>
                <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                  <CheckIcon />
                </div>
                <form
                  className="mt-8 space-y-6"
                  action="/api/token"
                  method="POST"
                  onSubmit={handleSubmit}
                >
                  <div className="-space-y-px rounded-md shadow-sm">
                    <div>
                      <label htmlFor="login-user-input" className="sr-only">
                        Benutzername
                      </label>
                      <input
                        id="login-user-input"
                        name="username"
                        type="text"
                        autoComplete="email"
                        required
                        className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-stone-500 focus:outline-none focus:ring-stone-500 sm:text-sm"
                        placeholder="Benutzername"
                      />
                    </div>
                    <div>
                      <label htmlFor="password" className="sr-only">
                        Passwort
                      </label>
                      <input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-stone-500 focus:outline-none focus:ring-stone-500 sm:text-sm"
                        placeholder="Passwort"
                      />
                    </div>
                  </div>
                  {loginfailed && (
                    <p x-show="failedlogin" className="text-red-500">
                      Falsche Zugangsdaten
                    </p>
                  )}
                  <a
                    className="block mt-5 text-center text-sm/6 font-medium text-indigo-600 hover:text-indigo-500"
                    href="/password-reset"
                  >
                    Passwort vergessen?
                  </a>

                  <div>
                    <button
                      type="submit"
                      className="group relative flex w-full justify-center rounded-md border border-transparent bg-stone-600 py-2 px-4 text-sm font-medium text-white hover:bg-stone-700 focus:outline-none focus:ring-2 focus:ring-stone-500 focus:ring-offset-2"
                    >
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                        <LockClosedIcon />
                      </span>
                      Anmelden
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function RessourceListGroup({
  ressources,
  staff,
  deleteRessource,
  updateTimeslots,
}) {
  return (
    <section>
      <h3 className="text-2xl mt-8 sticky top-0 pb-2 bg-white/90">
        {ressources[0].start.toLocaleString(undefined, {
          weekday: "short",
          year: "numeric",
          month: "numeric",
          day: "numeric",
        })}
      </h3>
      <ol>
        {ressources.map((ressource) => (
          <Ressource
            ressource={ressource}
            staff={staff}
            deleteRessource={deleteRessource}
            key={ressource.slot_id}
            updateTimeslots={updateTimeslots}
          />
        ))}
      </ol>
    </section>
  );
}

function RessourceList() {
  const [ressources, setRessources] = useState([]);
  const [filter, setFilter] = useState({});
  const [availableVariants, setAvailableVariants] = useState([]);
  const user = useContext(UserContext);
  const [staff] = useOutletContext();

  useEffect(() => {
    (async () => {
      const response = await fetch("/api/admin/variants", {
        credentials: "same-origin",
      });
      if (response.ok) {
        const data = await response.json();
        setAvailableVariants(data);
      }
    })();
  }, []);

  function filterRessources(ressources, myfilter) {
    return ressources.filter(
      (ressource) =>
        (!myfilter?.team ||
          myfilter.team.length == 0 ||
          ressource.assigned_staff.some((e) => myfilter.team.includes(e))) &&
        (!myfilter?.variants ||
          myfilter.variants.length == 0 ||
          ressource.available_ressources.some((e) =>
            myfilter.variants.includes(e)
          )) &&
        (!myfilter?.bookings ||
          myfilter.bookings.length == 0 ||
          (myfilter.bookings.includes("withBookings") &&
            ressource.bookings[0]) ||
          (myfilter.bookings.includes("withoutBookings") &&
            ressource.bookings[0] === null))
    );
  }

  async function updateTimeslots() {
    const response = await fetch("/api/admin/events", {
      credentials: "same-origin",
    });
    if (response.ok) {
      const data = await response.json();
      setRessources(data);
    }
  }

  useEffect(() => {
    /*(async () => {
      const response = await fetch("/api/admin/events", {
        credentials: "same-origin",
      });
      if (response.ok) {
        const data = await response.json();
        setRessources(data);
      }
    })();*/
    updateTimeslots();
  }, []);

  async function deleteRessource(slot_id) {
    const resp = await deleteTimeslot(slot_id);
    if (resp.ok) {
      setRessources(
        ressources.filter((ressource) => ressource.slot_id !== slot_id)
      );
    }
  }
  function addRessourceToList(newRessource) {
    setRessources(
      [...ressources, newRessource].sort((a, b) => a.start - b.start)
    );
  }

  function handleAddTimeslot() {
    // TODO
    NiceModal.show(AddTimeslotDialog, {
      ressource: null,
      addRessourceToState: addRessourceToList,
    });
  }

  let ressourcesGrouped = {};
  if (ressources.length) {
    // Group ressources by date
    ressourcesGrouped = filterRessources(ressources, filter).reduce(
      (groups, item) => {
        item.start = new Date(item.start);
        const group = groups[item.start.toLocaleDateString()] || [];
        group.push(item);
        groups[item.start.toLocaleDateString()] = group;
        return groups;
      },
      {}
    );
  }
  return (
    <>
      <div className="flex justify-between items-end">
        {user.admin && (
          <button
            type="button"
            onClick={handleAddTimeslot}
            className="flex items-center gap-x-1.5 rounded-md bg-orange-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <CalendarIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
            Termin hinzufügen
          </button>
        )}
        <RessourceFilter
          team={staff}
          pushFilterUpdate={setFilter}
          availableVariants={availableVariants}
        />
      </div>
      {ressourcesGrouped.length && <EmptyTimeslots />}

      {Object.keys(ressourcesGrouped).map((date) => (
        <RessourceListGroup
          ressources={ressourcesGrouped[date]}
          staff={staff}
          deleteRessource={deleteRessource}
          updateTimeslots={updateTimeslots}
          key={date}
        />
      ))}
    </>
  );
}

function Dashboard() {
  const [user, setUser] = useState(null);
  const [staff, setStaff] = useState(null);

  useEffect(() => {
    (async () => {
      const response = await fetch("/api/users/me/", {
        credentials: "same-origin",
      });
      if (response.ok) {
        const data = await response.json();
        setUser(data);
      } else {
        setUser(null);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (user) setStaff(await getTeam());
    })();
  }, [user]);

  function handleLogout(evt) {
    document.cookie =
      "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    window.location.reload();
  }
  function handleChangePasswword() {
    NiceModal.show(PasswordEditor, { user: user });
  }
  const userNavigation = [
    //  { name: "Your Profile", href: "#" },
    //  { name: "Settings", href: "#" },
    { name: "Abmelden", href: "#", onclick: handleLogout },
    { name: "Passwort ändern", href: "#", onclick: handleChangePasswword },
  ];

  if (!user) {
    return <LoginScreen setUser={setUser} />;
  }

  return (
    <UserContext.Provider value={user}>
      {/*
        This example requires updating your div:

        ```
        <html className="h-full">
        <body className="h-full">
        ```
      */}
      <div className="min-h-full">
        <Disclosure as="nav" className="border-b border-gray-200 bg-white">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 justify-between">
              <div className="flex">
                {/*<div className="flex flex-shrink-0 items-center">
                  <img
                    alt="bookingal"
                    src=""
                    className="block h-8 w-auto lg:hidden"
                  />
                  <img
                    alt="bookingal"
                    src=""
                    className="hidden h-8 w-auto lg:block"
                  />
                </div>*/}
                <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                  {navigation.map((item) =>
                    !user.admin && item.admin ? null : (
                      <a
                        key={item.name}
                        href={item.href}
                        aria-current={item.current ? "page" : undefined}
                        className={classNames(
                          item.current
                            ? "border-indigo-500 text-gray-900"
                            : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                          "inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium"
                        )}
                      >
                        {item.name}
                      </a>
                    )
                  )}
                </div>
              </div>
              <div className="hidden sm:ml-6 sm:flex sm:items-center">
                {/*<button
                  type="button"
                  className="relative rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span className="absolute -inset-1.5" />
                  <span className="sr-only">View notifications</span>
                  <BellIcon aria-hidden="true" className="h-6 w-6" />
                </button>*/}

                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-3">
                  <div>
                    <MenuButton className="relative flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">Open user menu</span>
                      {/*<img
                        alt=""
                        src={user.imageUrl}
                        className="h-8 w-8 rounded-full"
                      />*/}
                      {user.username}
                    </MenuButton>
                  </div>
                  <MenuItems
                    transition
                    className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                  >
                    {userNavigation.map((item) => (
                      <MenuItem
                        key={item.name}
                        as="button"
                        href={item.href}
                        onClick={item.onclick}
                        className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </MenuItems>
                </Menu>
              </div>
              <div className="-mr-2 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  <Bars3Icon
                    aria-hidden="true"
                    className="block h-6 w-6 group-data-[open]:hidden"
                  />
                  <XMarkIcon
                    aria-hidden="true"
                    className="hidden h-6 w-6 group-data-[open]:block"
                  />
                </DisclosureButton>
              </div>
            </div>
          </div>

          <DisclosurePanel className="sm:hidden">
            <div className="space-y-1 pb-3 pt-2">
              {navigation.map((item) =>
                !user.admin && item.admin ? null : (
                  <DisclosureButton
                    key={item.name}
                    as={NavLink}
                    to={item.href}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </DisclosureButton>
                )
              )}
            </div>
            <div className="border-t border-gray-200 pb-3 pt-4">
              <div className="flex items-center px-4">
                <div className="flex-shrink-0">
                  {/*<img alt="" src={user.imageUrl} className="h-10 w-10 rounded-full" />*/}
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-gray-800">
                    {user.name}
                  </div>
                  <div className="text-sm font-medium text-gray-500">
                    {user.email}
                  </div>
                </div>
                {/*<button
                  type="button"
                  className="relative ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span className="absolute -inset-1.5" />
                  <span className="sr-only">View notifications</span>
                  <BellIcon aria-hidden="true" className="h-6 w-6" />
                </button>*/}
              </div>
              <div className="mt-3 space-y-1">
                {userNavigation.map((item) => (
                  <DisclosureButton
                    key={item.name}
                    as="button"
                    href={item.href}
                    onClick={item.onclick}
                    className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                  >
                    {item.name}
                  </DisclosureButton>
                ))}
              </div>
            </div>
          </DisclosurePanel>
        </Disclosure>

        <div className="py-10">
          <header>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              {/*<h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
                Dashboard
              </h1>*/}
            </div>
          </header>
          <main>
            <div className="mx-auto max-w-7xl px-4 py-8 sm:px-6 lg:px-8">
              <Outlet context={[staff]} />
            </div>
          </main>
        </div>
      </div>
    </UserContext.Provider>
  );
}

function App() {
  return (
    <Routes>
      <Route index element={<Navigate replace to="/admin/" />} />
      <Route path="login" element={<Navigate replace to="/admin/" />} />
      <Route path="registration" element={<Registration />} />
      <Route path="password-reset" element={<PasswordReset />} />
      <Route path="admin" element={<Dashboard />}>
        <Route index element={<RessourceList />} />
        <Route path="settings" element={<Settings />} />
        <Route path="team" element={<UserList />} />
        <Route path="help" element={<Help />} />
      </Route>
    </Routes>
  );
}

export default App;
